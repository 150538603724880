"use client";

import { api } from "@/api/api";
import { BusinessPresetSubscription, City, IncidentPreset } from "@/types";
import {
  UseQueryOptions,
  keepPreviousData,
  useQuery,
} from "@tanstack/react-query";

type Props = {
  businessId: string;
};

type Response = {
  success: boolean;
  subscriptions: BusinessPresetSubscription[];
};

export function useBusinessPresetSubscriptions(
  { businessId }: Props,
  options?: Partial<UseQueryOptions<BusinessPresetSubscription[]>>,
) {
  return useQuery({
    queryKey: ["businesses", businessId, "subscriptions", "presets"],
    queryFn: async () => {
      const { data } = await api.get<Response>(
        `businesses/${businessId}/subscriptions/presets`
      );
      return data.subscriptions;  // Make sure this matches your API response
    },
    placeholderData: keepPreviousData,
    ...options,
  });
}