// "use client";
// import { api } from "@/api";
// import type { Incident, IncidentFilter } from "@/types";
// import { storage } from "@/util/storage";
// import {
//   UseInfiniteQueryOptions,
//   useInfiniteQuery,
//   InfiniteData,
// } from "@tanstack/react-query";
// import { useSelf } from "@/hooks/useSelf";

// type Response = {
//   total: number;
//   incidents: Incident[];
// };

// export function useIncidents(
//   { filter }: { filter?: IncidentFilter },
//   options?: Partial<UseInfiniteQueryOptions<Response, Error, InfiniteData<Response>>>
// ) {
//   const PAGE_SIZE = 100;
//   const { data: self } = useSelf();

//   return useInfiniteQuery<Response, Error, InfiniteData<Response>>({
//     queryKey: ["incidents", { filter }],
//     initialPageParam: 0,
//     refetchInterval: 955000,
//     refetchIntervalInBackground: true,
//     queryFn: async ({ pageParam: page, signal }) => {
//       try {
//         // Get stored filter
//         const storedFilter = await storage.get("incidentFilter");
//         const parsedStoredFilter = storedFilter ? JSON.parse(storedFilter) : {};

//         // Get user's preset subscriptions if user is available
//         let userPresets: string[] = [];
//         if (self?.id) {
//           try {
//             const userPresetsResponse = await api.get(`users/${self.id}/subscriptions/presets`);
//             userPresets = userPresetsResponse.data?.presets?.map((p: any) => p.label) || [];
//           } catch (error) {
//             console.error('Error fetching user presets:', error);
//           }
//         }

//         const {
//           excludeIncidentTypes,
//           excludePresets,
//           datesRelativeTo,
//           creator,
//           comments,
//           latitude,
//           longitude,
//           radius,
//           incidentStatus,
//           category,
//           from,
//           to,
//           keywords,
//           legal,
//           location,
//           presets = userPresets, // Use user presets as default if none provided
//           full,
//           active,
//           line1,
//           line2,
//           city,
//           county,
//           state,
//           incidentId,
//           alarm,
//           propertyAddress,
//           legalDetails,
//           stateIds,
//           countyInclusions,
//           countyExclusions,
//           cityInclusions,
//           cityExclusions,
//           allCountyStateIds,
//         } = filter ?? {};

//         // Merge filters and ensure presets are included
//         const mergedFilter = {
//           ...parsedStoredFilter,
//           ...filter,
//           presets: presets?.length ? presets : userPresets,
//         };

//         const cleanParams = {
//           ...mergedFilter,
//           excludeIncidentTypes,
//           excludePresets,
//           datesRelativeTo,
//           creator,
//           comments,
//           latitude,
//           longitude,
//           radius,
//           incidentStatus,
//           category,
//           limit: PAGE_SIZE,
//           offset: page as number * PAGE_SIZE,
//           keywords,
//           legal,
//           location,
//           from,
//           to,
//           full,
//           active,
//           line1,
//           line2,
//           city,
//           county,
//           state,
//           incidentId,
//           alarm,
//           propertyAddress,
//           legalDetails,
//           stateIds,
//           countyInclusions: mergedFilter.countyInclusions?.filter(Boolean) || [],
//           cityInclusions: mergedFilter.cityInclusions || [],
//           cityExclusions: mergedFilter.cityExclusions || [],
//           countyExclusions: mergedFilter.countyExclusions || [],
//           allCountyStateIds,
//         };

//         // Clean up undefined and boolean values
//         Object.entries(cleanParams).forEach(([key, value]) => {
//           if (value === undefined) {
//             delete (cleanParams as any)[key];
//           } else if (typeof value === "boolean") {
//             (cleanParams as any)[key] = value ? "1" : "";
//           }
//         });

//         console.log('Clean params with presets:', {
//           ...cleanParams,
//           presets: cleanParams.presets,
//           userPresets
//         });

//         const response = await api.get<Response>("incidents", {
//           params: cleanParams,
//           signal,
//         });

//         return response.data;
//       } catch (error) {
//         console.error('Error in useIncidents:', error);
//         throw error;
//       }
//     },
//     getNextPageParam: (lastPage, allPages) => {
//       if (lastPage.incidents.length < PAGE_SIZE) {
//         return undefined;
//       }
//       return allPages.length;
//     },
//     enabled: !!self?.id, // Only enable the query when we have a user
//     ...options,
//   });
// }





"use client";
import { api } from "@/api";
import type { Incident, IncidentFilter } from "@/types";
import { storage } from "@/util/storage";
import {
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  InfiniteData,
} from "@tanstack/react-query";
import { useSelf } from "@/hooks/useSelf";

type Response = {
  total: number;
  incidents: Incident[];
};

export function useIncidents(
  { filter }: { filter?: IncidentFilter },
  options?: Partial<UseInfiniteQueryOptions<Response, Error, InfiniteData<Response>>>
) {
  const PAGE_SIZE = 100;
  const { data: self } = useSelf();

  return useInfiniteQuery<Response, Error, InfiniteData<Response>>({
    queryKey: ["incidents", { filter }],
    initialPageParam: 0,
    refetchInterval: 5000,
    refetchIntervalInBackground: true,
    queryFn: async ({ pageParam: page, signal }) => {
      try {
        const storedFilter = await storage.get("incidentFilter");
        const parsedStoredFilter = storedFilter ? JSON.parse(storedFilter) : {};

        let userPresets: string[] = [];
        if (self?.id) {
          try {
            const userPresetsResponse = await api.get(`users/${self.id}/subscriptions/presets`);
            userPresets = userPresetsResponse.data?.presets?.map((p: any) => p.label) || [];

            // console.log(userPresetsResponse)

          } catch (error) {
            console.error('Error fetching user presets:', error);
          }
        }

        const {
          excludeIncidentTypes,
          excludePresets,
          datesRelativeTo,
          creator,
          comments,
          latitude,
          longitude,
          radius,
          incidentStatus,
          category,
          from,
          to,
          keywords,
          legal,
          location,
          presets = userPresets,
          full,
          active,
          line1,
          line2,
          city,
          county,
          state,
          incidentId,
          alarm,
          propertyAddress,
          legalDetails,
          stateIds,
          countyInclusions,
          countyExclusions,
          cityInclusions,
          cityExclusions,
          allCountyStateIds,
        } = filter ?? {};

        const mergedFilter = {
          ...parsedStoredFilter,
          ...filter,
          presets: presets?.length ? presets : userPresets,
        };

        const cleanParams = {
          ...mergedFilter,
          excludeIncidentTypes,
          excludePresets,
          datesRelativeTo,
          creator,
          comments,
          latitude,
          longitude,
          radius,
          incidentStatus,
          category,
          limit: PAGE_SIZE,
          offset: page as number * PAGE_SIZE,
          keywords,
          legal,
          location,
          from,
          to,
          full,
          active,
          line1,
          line2,
          city,
          county,
          state,
          incidentId,
          alarm,
          propertyAddress,
          legalDetails,
          stateIds,
          countyInclusions: mergedFilter.countyInclusions?.filter(Boolean) || [],
          cityInclusions: mergedFilter.cityInclusions || [],
          cityExclusions: mergedFilter.cityExclusions || [],
          countyExclusions: mergedFilter.countyExclusions || [],
          allCountyStateIds,
        };

        // Clean up undefined and boolean values
        Object.entries(cleanParams).forEach(([key, value]) => {
          if (value === undefined) {
            delete (cleanParams as any)[key];
          } else if (typeof value === "boolean") {
            (cleanParams as any)[key] = value ? "1" : "";
          }
        });

        // console.log('Clean params with presets:', {
        //   ...cleanParams,
        //   presets: cleanParams.presets,
        //   userPresets
        // });

        const response = await api.get<Response>("incidents", {
          params: cleanParams,
          signal,
        });

        return response.data;
      } catch (error) {
        console.error('Error in useIncidents:', error);
        throw error;
      }
    },
    getNextPageParam: (lastPage, allPages) => {
      if (lastPage.incidents.length < PAGE_SIZE) {
        return undefined;
      }
      return allPages.length;
    },
    enabled: !!self?.id, 
    ...options,
  });
}


async function getUserPresets(userId?: string): Promise<string[]> {
  if (!userId) return [];
  try {
    const userPresetsResponse = await api.get(`users/${userId}/subscriptions/presets`);
    return userPresetsResponse.data?.presets?.map((p: any) => p.label) || [];
  } catch (error) {
    console.error('Error fetching user presets:', error);
    return [];
  }
}